@import '../common/variables.scss';

#about-us-layout {
    height: 75vh;
    width: 100vw;
    background-color: $light-grey;
    display: grid;
    grid-template-rows: 1fr 6fr;
    grid-template-columns: 50% 50%;
}

#about-us-title {
    color: $black;
    letter-spacing: 0.05em;
    font-size: 2.5rem;
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    text-align: center;
}

#contact-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 5% 10% 10%;
}

.about-us-name-sections {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headshot {
    height: 200px;
    width: 200px;
    border-radius: 50%;
}

.owner-name {
    margin-bottom: 0;
}

.owner-email {
    background-color: transparent;
    color: $black;
    font-family: $p-font;
    font-weight: 500;
}

#about-us-text {
    grid-column: 2 / 3;
    text-align: center;
    color: $dark-blue;
    letter-spacing: 0.03rem;
    font-weight: 400;
    margin: 0 15% 0 5%;
    line-height: 2rem;
    font-size: 1.2rem;
    text-align: justify;
}

.email-link {
    font-weight: 700;
    text-decoration: none;
    color: $dark-orange;
}

@media screen and (max-width: 660px) {
    #about-us-layout {
        height: fit-content;
        display: flex;
        flex-direction: column;
    }

    #contact-section {
        margin-bottom: 8%;
    }

    .headshot {
        height: 100px;
        width: 100px;
    }

    .owner-name {
        margin-top: 5px;
        font-size: 1rem;
    }

    .owner-email {
        font-size: 0.7rem;
    }

    #about-us-text {
        margin: 0 12% 10% 12%;
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@media screen and (max-width: 1275px) and (min-width: 661px) {
    #about-us-layout {
        height: fit-content;
        grid-template-rows: 1fr 5fr;
        padding-bottom: 5%;
        align-items: center;
    }

    #contact-section {
        flex-direction: column;
        row-gap: 15px;
        margin: 0 15% 0 15%;
    }

    .owner-name {
        margin-top: 5px;
    }
}
